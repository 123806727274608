import React, { useState } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { introdata, meta } from "../../content_option";
import 'react-fancybox/lib/fancybox.css'
import FancyVideo from 'react-videojs-fancybox';
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { FaGithub, FaTwitter,FaFacebookF, FaLinkedin, FaInstagram } from "react-icons/fa";
import { socialprofils } from "../../content_option";



export default function Home() {
  const [url , setUrl] = useState();
  const fadeImages = [
    "https://demiera.com/images/banner-demiera-films-dos.jpg",
    "https://demiera.com/images/banner-demiera-films-tres.jpg",
    "https://demiera.com/images/banner-demiera-films-2x.jpg"  
  ];
  const properties = {
    duration: 2000,
    arrows: false,
    infinite: true
  };
  
  return (
    
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title> {meta.title}</title>
          <meta name="description" content={meta.description} />
         
        </Helmet>
        <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxone'}>
          <div
            className="h_bg-image order-1 order-lg-2 h-100 ">
              <div className="slide-container">
            <Fade {...properties}>
              <div className="each-fade">
                <img src={fadeImages[0]}  />
              </div>
              <div className="each-fade">
                <img src={fadeImages[1]} />
              </div>
              <div className="each-fade">
                <img src={fadeImages[2]} />
              </div>
            </Fade>
          </div>
            </div>
             
          </div>

        
       <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxtwelve'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
          <div id="title"><h1 id="acheuno">SPECIAL</h1><p id="titlep">SHAINNY</p></div>
               <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/aEqzgqnss6k?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/FOTO WEB SHAINNY.jpg"
              id={"sintel11"}
         />
          </div>
         
        
        </div>


      <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxeleven'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
          <div id="title"><h1 id="acheuno">SHOOTING SESSION</h1><p id="titlep">MORENGAR</p></div>
               <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/3sBam-dDu9I?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/Captura de pantalla 2021-11-17 a las 14.18.02.jpg"
              id={"sintel10"}
         />
          </div>
         
        
        </div>

          
        <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxtwo'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
            <div id="title"><h1 id="acheuno">DEBILIDAD</h1><p id="titlep">MR KARTY FT SHAINNY</p></div>
                <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/YI0wELdgNWM?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/demiera-debilidad-shainny.jpg"
               id={"sintel"}
               />
          </div>
        
        
        </div>
        <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxthree'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
          <div id="title"><h1 id="acheuno">UN CALL</h1><p id="titlep">TODO EL RATO</p></div>
               <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/oheAuvChZls?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/demiera-uncall-todo.jpg"
               id={"sintel2"}
               />
          </div>
         
        
        </div>
        <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxfour'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
          <div id="title"><h1 id="acheuno">QUE TU QUIERES?</h1><p id="titlep">BUGGATINO</p></div>
               <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/zKWLag4Dhjs?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/demiera-bugga-tres.jpg"
              id={"sintel3"}
         />
          </div>
         
        
        </div>
        <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxfive'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
          <div id="title"><h1 id="acheuno">ELA AFRODRILL 3</h1><p id="titlep">TODO EL RATO</p></div>
               <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/yQHY2YdoTnA?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/demiera-afrodrilltres-ela.jpg"
              id={"sintel4"}
         />
          </div>
         
        
        </div>
        <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxsix'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
          <div id="title"><h1 id="acheuno">UNICA BALA</h1><p id="titlep">TR FT CUBAN BEEF</p></div>
               <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/_96hJZ3y2hc?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/demiera-unica-bala.jpg"
              id={"sintel5"}
         />
          </div>
         
        
        </div>
        <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxseven'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
          <div id="title"><h1 id="acheuno">HATERS</h1><p id="titlep">TODO EL RATO</p></div>
               <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/_7AuJ_wYAww?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/demiera-haters.jpg"
              id={"sintel6"}
         />
          </div>
         
        
        </div>
        <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxeight'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
          <div id="title"><h1 id="acheuno">ELYSA</h1><p id="titlep">COMMERCIAL SPOT</p></div>
               <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/-wFaMO99yfs?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/demiera-elisa-spot.jpg"
              id={"sintel7"}
         />
          </div>
         
        
        </div>
        <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxnine'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
          <div id="title"><h1 id="acheuno">ENVIDIA AFRODRILL 2</h1><p id="titlep">TODO EL RATO</p></div>
               <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/mtH27jCYkeQ?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/demiera-envidia.jpg"
              id={"sintel8"}
         />
          </div>
         
        
        </div>
        <div className="intro_sec d-block d-lg-flex align-items-center " id={'boxten'}>
          <div className="h_bg-image order-1 order-lg-12 h-100 ">
          <div id="title"><h1 id="acheuno">DINERO AFRODRILL 1</h1><p id="titlep">TODO EL RATO</p></div>
               <FancyVideo
               type= 'video/mp4'
               source="https://www.youtube-nocookie.com/embed/MsKC84xquNg?vq=hd1080&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent"
               poster="https://demiera.com/images/demiera-dinero.jpg"
              id={"sintel9"}
         />
          </div>
         
        
        </div>

        
     
      </section>

     
      <footer class="page-footer font-small blue pt-4"><div class="container-fluid text-center text-md-left"><div class="row">
            <div class="col-md-4 mt-md-0 mt-3">
              <h5 class="text-uppercase" id="logotext">DEMIERA</h5>
              <p id="logotextdos">FILMS</p>
              </div>
            <hr class="clearfix w-100 d-md-none pb-3"/>
            <div class="col-md-2 mb-md-0 mb-2">
              <h5 class="text-uppercase"><a href="/">Home</a></h5>
            </div>
            <div class="col-md-2 mb-md-0 mb-2">
              <h5 class="text-uppercase"><a href="/portfolio">Photography</a></h5>
            </div>
            <div class="col-md-2 mb-md-0 mb-2">
              <h5 class="text-uppercase"><a href="/about">About me</a></h5>
            </div>
            <div class="col-md-2 mb-md-0 mb-2">
              <h5 class="text-uppercase"><a href="/contact">Contact</a></h5>
              </div>
            </div>
          </div>
        <div class="footer-copyright text-center py-3">
        
            <ul id="listfooter">
              <li id="list">
                <a href={socialprofils.instagram}>
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a href={socialprofils.linkedin}>
                  <FaLinkedin />
                </a>
              </li>
            </ul>
            <br></br>
          
           <a href="https://condedaw.com/"> condedaw</a> © 2022 
         
        </div>
        </footer>
      
 
    </HelmetProvider>
  );
}




