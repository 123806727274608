const logotext = "DEMIERA";
const meta = {
    title: "Demiera",
    description: "Film Maker & Photographer based in Madrid, Spain.",
};

const introdata = {
    title: "Hola soy Demiera",
    animated: {
        first: "I love films",
        second: "I code cool websites",
        third: "I develop mobile apps",
    },
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum",
    your_img_url: "http://demiera.com/images/banner-demiera-films.jpg",
};

const dataabout = {
    title: "about my self",
    aboutme: "Soy Carlos, trabajador freelance, Editor de video, Cámara y Fotógrafo profesional. Actualmente creo contenido  para artistas musicales, así como también otras empresas de diversos sectores. Desarrollo proyectos de forma creativa y dinámica.  ",
};
const worktimeline = [{
        jobtitle: "Designer of week",
        where: "YAdfi",
        date: "2021",
    },
    {
        jobtitle: "Designer of week",
        where: "Jamalya",
        date: "2020",
    },
    {
        jobtitle: "Designer of week",
        where: "ALquds",
        date: "2019",
    },
    {
        jobtitle: "Designer of week",
        where: "ALquds",
        date: "2018",
    }
];

const skills = [{
        name: "Adobe Premiere",
        value: 100,
    },
    {
        name: "Adobe After Effects",
        value: 75,
    },
    {
        name: "Adobe LightRoom",
        value: 100,
    },
    {
        name: "Adobe PhotoShop",
        value: 90,
    },
    {
        name: "Adobe Illustrator",
        value: 75,
    },
];

const services = [{
        title: "Videos Musicales",
        description: "Especializado en videos musicales, cuento con un equipo de calidad y preparado para ello, trabajamos codo a codo junto a artistas del ámbito nacional siempre apoyando el panorama. Todo el Rato, Cuban beef, Buggatino, Mr.Karty, Shainny, etc..",
    },
    {
        title: "Videos promocionales y corporativos",
        description: "Videos comerciales para marcas, videos de eventos deportivos, videos promocionales para empresas, etc...Cada empresa o marca debería aprovechar el poder del audiovisual para conseguir más ventas, clientes potenciales y publicidad.",
    },
    {
        title: "Fotografía profesional (Moda, Deporte, Producto, LifeStyle)",
        description: "Todo tipo de trabajos fotográficos, creación de contenido para redes sociales y webs, sesiones en estudio, fotografía en eventos deportivos, etc... Me adapto al cliente para ofrecerle la mejor imagen y visual de su producto o empresa.",
    },
];

const dataportfolio = [{
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "csaenzm.projectzz@gmail.com",
    YOUR_FONE: "",
    description: "¿Tienes una idea o un proyecto? ¿No tienes ninguna idea? Escríbeme y trabajaremos en ello para obtener el mejor resultado. ",
    YOUR_SERVICE_ID: "service_9ur960d",
    YOUR_TEMPLATE_ID: "template_ywmz8zd",
    YOUR_USER_ID: "user_N0fj3EKqLv05NJNXxWbTR",
};

const socialprofils = {
    facebook: "https://facebook.com",
    linkedin: "https://linkedin.com/in/carlos-sáenz-de-miera-patín-100615193/?originalSubdomain=es",
    instagram: "https://instagram.com/demiera__?utm_medium=copy_link"
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};