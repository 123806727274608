import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { dataportfolio, meta } from "../../content_option";
import { LightBox } from 'react-lightbox-pack'; // <--- Importing LightBox Pack
import "react-lightbox-pack/dist/index.css";
import data from './data.json'; 
import { socialprofils } from "../../content_option";
import { FaGithub, FaTwitter,FaFacebookF, FaLinkedin, FaInstagram } from "react-icons/fa";




function Portfolio() {

  const [toggle, setToggle] =  React.useState(false);
	const [sIndex, setSIndex] =  React.useState(0);

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Portfolio | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
      
        <div className="mb-10 po_items_ho">
          {data.map((item, i) => {
            return (
              <div key={i} className="po_item">
                 
         

                 <img
                  key={item.id}
                  src={item.image}
                  alt={item.title}
                  style={{ maxHeight:  "80vh", maxWidth:  "50vw" }}
                  onClick={() => {
                  lightBoxHandler(true, i);
                  window.scrollTo(0, 0);
                  }}
                />
                
              </div>
            );
          })}

        <LightBox
				state={toggle}
        event={lightBoxHandler}
        data={data}
        imageWidth="100%"
        imageHeight="100%"
        thumbnailHeight={0}
        thumbnailWidth={0}
        setImageIndex={setSIndex}
        imageIndex={sIndex}
			/>
        </div>
      </Container>

      <footer class="page-footer font-small blue pt-4"><div class="container-fluid text-center text-md-left"><div class="row">
            <div class="col-md-4 mt-md-0 mt-3">
              <h5 class="text-uppercase" id="logotext">DEMIERA</h5>
              <p id="logotextdos">FILMS</p>
              </div>
            <hr class="clearfix w-100 d-md-none pb-3"/>
            <div class="col-md-2 mb-md-0 mb-2">
              <h5 class="text-uppercase"><a href="/">Home</a></h5>
            </div>
            <div class="col-md-2 mb-md-0 mb-2">
              <h5 class="text-uppercase"><a href="/portfolio">Photography</a></h5>
            </div>
            <div class="col-md-2 mb-md-0 mb-2">
              <h5 class="text-uppercase"><a href="/about">About me</a></h5>
            </div>
            <div class="col-md-2 mb-md-0 mb-2">
              <h5 class="text-uppercase"><a href="/contact">Contact</a></h5>
              </div>
            </div>
          </div>
        <div class="footer-copyright text-center py-3">
        
            <ul id="listfooter">
              <li id="list">
                <a href={socialprofils.instagram}>
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a href={socialprofils.linkedin}>
                  <FaLinkedin />
                </a>
              </li>
            </ul>
            <br></br>
          
           <a href="https://condedaw.com/"> condedaw</a> © 2021 
         
        </div>
        </footer>
    </HelmetProvider>
  );
}

export default Portfolio;
